import React from 'react';

import Icon, { IconNameType } from 'components/atoms/icon';
import Link, { LinkUrlType } from 'components/atoms/link';
import { WpImageType } from 'components/atoms/wp-image';

import {
  StyledContact,
  StyledContacts,
  StyledDescription,
  StyledName,
  StyledPlaceHolderImage,
  StyledPosition,
  StyledPositionTitle,
  StyledWpImage,
  StyledWrapper,
} from './business-card.styles';

export type BusinessCardType = {
  contactList: { type: IconNameType; link: LinkUrlType }[];
  fullName?: string;
  image: WpImageType;
  text?: string;
  position?: string;
  positionTitle?: string;
};

const BusinessCard = ({
  contactList,
  fullName,
  image,
  text,
  position,
  positionTitle,
}: BusinessCardType) => (
  <StyledWrapper>
    <StyledPosition variant="title3" component="h3" color="gray40">
      {position}
    </StyledPosition>
    {image ? (
      <StyledWpImage {...image} objectFit="contain" />
    ) : (
      <StyledPlaceHolderImage name="avatar-placeholder" />
    )}
    <StyledName variant="title2" component="h2">
      {fullName}
    </StyledName>
    <StyledPositionTitle variant="title3" component="h3" color="gray40">
      {positionTitle}
    </StyledPositionTitle>
    <StyledDescription variant="body1">{text}</StyledDescription>

    {contactList ? (
      <StyledContacts>
        {contactList.map(({ type, link }) => (
          <StyledContact>
            <Link {...link} ariaLabel={type} key={type}>
              <Icon name={type} />
            </Link>
          </StyledContact>
        ))}
      </StyledContacts>
    ) : null}
  </StyledWrapper>
);

export default BusinessCard;
