import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div``;
export const WrapperAdditionalInfoStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 0;
  div:not(:first-child) {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 50px;
      margin-inline: ${({ theme }) => theme.gap.xs};
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;
export const WrapperContentTextJobOfferPage = styled.div`
  ul {
    margin-block: ${({ theme }) => theme.gap.sm};

    li {
      margin-bottom: ${({ theme }) => theme.gap.xs};
      font-size: ${({ theme }) => theme.fontSize};
      display: flex;
      &::before {
        margin-right: ${({ theme }) => theme.gap.xxs};
        content: '•';
        color: ${({ theme }) => theme.colors.primary50};
      }
    }
  }

  strong {
    font-weight: 600;
    text-decoration: unset;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: ${({ theme }) => theme.gap.sm};
  }
  ${breakpoint.md} {
    ul {
      margin-block: ${({ theme }) => theme.gap.md};
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: ${({ theme }) => theme.gap.md};
    }
  }
`;

export const StyledTextSalary = styled(Typography)`
  color: ${({ theme }) => theme.colors.salaryOrange};
`;
