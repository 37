import React from 'react';

import Typography from 'components/atoms/typography';

import {
  StyledTextSalary,
  StyledWrapper,
  WrapperAdditionalInfoStyle,
} from './job-offer-sub-titles.styles';

export type JobOfferSubTitlesType = Record<
  'city' | 'voivodship' | 'workmode' | 'contracttypes' | 'salaryfrom' | 'salaryto',
  string
>;

const JobOfferSubTitles = ({
  workmode,
  city,
  voivodship,
  contracttypes,
  salaryfrom,
  salaryto,
}: JobOfferSubTitlesType) => {
  const formattedContractTypes = Array.isArray(contracttypes)
    ? contracttypes.join(', ')
    : contracttypes || '';
  return (
    <StyledWrapper>
      <WrapperAdditionalInfoStyle>
        <Typography
          html={`${city ? `${city}, ` : ''}${voivodship}`}
          color="white"
          variant="title4"
        />
        <Typography html={workmode} color="white" variant="title4" />
        <Typography html={formattedContractTypes} color="white" variant="title4" />
        {salaryfrom ? (
          <div>
            {salaryfrom && salaryto !== null ? (
              <StyledTextSalary
                html={`Wynagrodzenie od ${salaryfrom} zł brutto do ${salaryto} zł brutto`}
                color="white"
                variant="title4"
              />
            ) : (
              <StyledTextSalary
                html={`Wynagrodzenie od ${salaryfrom}`}
                color="white"
                variant="title4"
              />
            )}
          </div>
        ) : null}
      </WrapperAdditionalInfoStyle>
    </StyledWrapper>
  );
};

export default JobOfferSubTitles;
