import React from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumb as BreadcrumbPluginComponent } from 'gatsby-plugin-breadcrumb';
import type { PageContextType } from 'types';

import useSiteMetadata from 'hooks/useSiteMetadata';

export type BreadcrumbType = {
  pageContext: PageContextType;
  customCrumbLabel?: string;
};

const labelMappings = {
  'media-service': 'Media Service',
  'payroll-consulting': 'Payroll Consulting',
  'hr-consulting': 'HR Consulting',
  'it-contracting': 'IT Contracting',
  'executive-search': 'Executive Search',
  'skontaktuj-sie-z-nami': 'Skontaktuj sie z nami',
  'employer-branding': 'Employer Branding',
  'centra-uslug-wspolnych': 'Centra Usług Wspólnych',
  'consumer-goods': 'Consumer Goods',
  'transport-logistyka': 'Transport & Logistyka',
  'dolacz-do-nas': 'Dołącz do nas',
};

const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const Breadcrumb = ({ pageContext, customCrumbLabel }: BreadcrumbType) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const isEnglish = crumbs.some((crumb) => crumb.pathname.includes('/en/'));
  const { siteUrl } = useSiteMetadata();
  const filteredCrumbs = crumbs.filter(
    (crumb) =>
      !/\b\d+\b/.test(crumb.pathname) &&
      crumb.pathname !== '/en' &&
      !crumb.crumbLabel.toLowerCase().includes('tag')
  );

  const ensureTrailingSlash = (path) => {
    if (!path.endsWith('/')) {
      return `${path}/`;
    }
    return path;
  };

  const customCrumbs = filteredCrumbs.map((crumb, index) => {
    const updatedCrumb = {
      ...crumb,
      pathname: ensureTrailingSlash(crumb.pathname),
    };

    const mappedLabel = labelMappings[crumb.crumbLabel];
    if (index === 0 && isEnglish) {
      return {
        ...updatedCrumb,
        pathname: '/en/home/',
      };
    }

    if (mappedLabel) {
      return {
        ...updatedCrumb,
        crumbLabel: mappedLabel,
      };
    }

    const formattedLabel = crumb.crumbLabel
      .split('-')
      .map((word, idx) => (idx === 0 ? capitalizeFirstLetter(word) : word))
      .join(' ');

    return {
      ...updatedCrumb,
      crumbLabel: formattedLabel,
    };
  });

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: customCrumbs.map((crumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: crumb.crumbLabel,
      item: `${siteUrl}${crumb.pathname}`,
    })),
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <BreadcrumbPluginComponent
        crumbs={customCrumbs}
        crumbLabel={customCrumbLabel ?? null}
        crumbSeparator="→"
      />
    </>
  );
};

export default Breadcrumb;
