import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';
import { AllJobOfferType, JobOfferPageType } from 'types/job-offers';

import Layout from 'components/templates/layout';
import ApplyOffer from 'components/organisms/apply-offer';
import JobOffersSlider from 'components/organisms/job-offers-slider';
import RecruiterSection from 'components/organisms/recruiter-section';
import BackgroundImageOffer from 'components/molecules/background-image-offer';
import { StyledDotsGridOffer } from 'components/molecules/job-offer-page-style/job-offer-page-style.styles';
import JobOfferSubTitles from 'components/molecules/job-offer-sub-titles';
import { WrapperContentTextJobOfferPage } from 'components/molecules/job-offer-sub-titles/job-offer-sub-titles.styles';
// eslint-disable-next-line import/no-unresolved
import SimpleWrapper from 'components/molecules/simple-wrapper';
import Badge from 'components/atoms/badge';
import Breadcrumb from 'components/atoms/breadcrumb';
import Typography from 'components/atoms/typography';

import { useScreenService } from 'hooks/use-screen-service';

import useCurrentLanguage from '../hooks/use-current-language';

type DataProps = {
  jobOfferPage: JobOfferPageType;
  allJobOfferType: AllJobOfferType;
};

const JobOfferPage = ({
  pageContext,
  data: { jobOfferPage, allJobOfferType },
}: PageProps<DataProps, PageContextType>) => {
  const currentPageData = jobOfferPage.edges[0]?.node;
  const offerDate = new Date(currentPageData.date);
  const currentDate = new Date();
  const daysDifference = Math.floor(
    (currentDate.getTime() - offerDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const replaceNewlinesAndTabs = (text) => {
    const replacedString = text.replace(/\\r\\n/g, '').replace(/\\t/g, '');

    return replacedString;
  };
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { title } = currentPageData;
  const {
    task,
    requirements,
    offer,
    contracttypes,
    workmode,
    city,
    category,
    voivodship,
    salaryto,
    salaryfrom,
    applicationurl,
    leader,
    applicationprocedure,
    recruiterPerson,
    backgroundImage,
  } = currentPageData?.offersData || {};
  const currentLanguage = useCurrentLanguage();
  const showBadge = daysDifference <= 7;
  const { isMobile } = useScreenService();
  const formattedLeader = leader ? replaceNewlinesAndTabs(leader) : '';
  const formattedApplicationprocedure = applicationprocedure
    ? replaceNewlinesAndTabs(applicationprocedure)
    : '';
  const formattedTask = task ? replaceNewlinesAndTabs(task) : '';
  const formattedRequirements = requirements ? replaceNewlinesAndTabs(requirements) : '';
  const formattedOffer = offer ? replaceNewlinesAndTabs(offer) : '';
  const similarOffersText = currentLanguage === 'PL' ? 'Podobne oferty' : 'Similar offers';
  return (
    <Layout {...{ pageContext }}>
      <SimpleWrapper
        bgColor={theme.colors.complementary1}
        padding={`${theme.gap.md} 0 ${theme.gap.xl} 0`}
        mobilePadding={`${theme.gap.sm} ${theme.gap.sm} ${theme.gap.mdl}`}
        flexDirection="column"
      >
        <Breadcrumb {...{ pageContext }} />
        <SimpleWrapper
          flexDirection="column"
          margin={`${theme.gap.xl} auto 0 auto`}
          maxWidth={theme.wrapperXs}
          gap={theme.gap.md}
          gapMobile={theme.gap.sm}
          width="100%"
          padding="0"
          mobilePadding="0"
        >
          <SimpleWrapper
            flexDirection="row"
            margin="0"
            maxWidth={theme.wrapperXs}
            gap={theme.gap.xs}
            padding="0"
            mobilePadding={`${theme.gap.md} 0 0 0`}
          >
            {showBadge ? <Badge typeOrange label="NOWA" /> : null}
            {category ? <Badge typeOrange label={category} /> : null}
          </SimpleWrapper>
          <Typography html={title} color="white" component="h1" variant="title1" />
          <Typography html={formattedLeader} color="whiteAlpha72" variant="title10" />
          <JobOfferSubTitles
            city={city}
            salaryto={salaryto}
            voivodship={voivodship}
            workmode={workmode}
            contracttypes={contracttypes}
            salaryfrom={salaryfrom}
          />
          <StyledDotsGridOffer
            width={isMobile ? 0 : 2}
            height={isMobile ? 0 : 7}
            color={isMobile ? 'complementary2' : 'complementary2'}
          />
        </SimpleWrapper>
      </SimpleWrapper>
      <SimpleWrapper
        margin={`${theme.gap.xl} auto`}
        padding="0"
        mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        maxWidth={theme.wrapperXs}
        justifyContent="space-between"
      >
        <SimpleWrapper maxWidth="635px" padding="0" mobilePadding="0">
          <WrapperContentTextJobOfferPage>
            <Typography html={formattedTask} color="gray100" variant="body4" />
            <Typography html={formattedRequirements} color="gray100" variant="body4" />
            <Typography html={formattedOffer} color="gray100" variant="body4" />
          </WrapperContentTextJobOfferPage>
        </SimpleWrapper>
        <ApplyOffer
          city={city}
          workmode={workmode}
          voivodship={voivodship}
          applicationprocedure={formattedApplicationprocedure}
          applicationurl={applicationurl}
        />
      </SimpleWrapper>
      <BackgroundImageOffer backgroundImage={backgroundImage} />
      <RecruiterSection {...recruiterPerson} />
      {allJobOfferType.edges && allJobOfferType.edges.length > 0 ? (
        <JobOffersSlider heading={similarOffersText} cards={allJobOfferType.edges} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query JobOfferPage($uriOffer: String, $category: String!) {
    jobOfferPage: allWpJobOffer(filter: { uri: { eq: $uriOffer } }) {
      edges {
        node {
          title
          date
          offersData {
            externalid
            consultant
            leader
            position
            task
            requirements
            offer
            applicationprocedure
            category
            voivodship
            applicationurl
            city
            workmode
            contracttypes
            salaryfrom
            salaryto
            unit
            backgroundImage {
              ...WpImageFragment
            }
            recruiterPerson: rektuter {
              ... on WpRecruiter {
                recruiterData: recruiter {
                  backgroundImage {
                    altText
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  profileImage {
                    altText
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  email
                  department
                  linkdin
                  fullName: name
                  consultantDescription
                  consultantDescriptionEn
                }
              }
            }
          }
        }
      }
    }
    allJobOfferType: allWpJobOffer(filter: { offersData: { category: { eq: $category } } }) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant

            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
            workmode
          }
        }
      }
    }
  }
`;

export default JobOfferPage;
