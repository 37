import React from 'react';
import { DefaultType } from 'types/default';
import { RecruiterType } from 'types/job-offers';

import BusinessCard from 'components/molecules/business-card';
import { IconNameType } from 'components/atoms/icon';
import { LinkUrlType } from 'components/atoms/link';

import { useScreenService } from 'hooks/use-screen-service';

import useCurrentLanguage from '../../../hooks/use-current-language';

import {
  StyledCardWrapper,
  StyledContent,
  StyledDotsGrid,
  StyledDotsGridLeft,
  StyledNoWpImage,
  StyledWpImage,
  StyledWrapper,
} from './recruiter-section.styles';

export type RecruiterSectionType = RecruiterType & DefaultType;

const RecruiterSection = ({ recruiterData, margin, mobileMargin }: RecruiterSectionType) => {
  const { isMdUp } = useScreenService();
  const currentLanguage = useCurrentLanguage();
  const recruiterText = currentLanguage === 'PL' ? 'Rekrutację prowadzi' : 'Recruitment is led by';
  let consultantDescriptionText = '';
  if (recruiterData) {
    if (currentLanguage === 'PL') {
      consultantDescriptionText = recruiterData.consultantDescription || ' ';
    } else {
      consultantDescriptionText = recruiterData.consultantDescriptionEn || ' ';
    }
  }
  const contactList: { type: IconNameType; link: LinkUrlType }[] = [
    {
      type: 'linkedin',
      link: {
        ariaLabel: 'linkedin',
        external: {
          target: '',
          url: recruiterData?.linkdin,
        },
      },
    },
    {
      type: 'email',
      link: {
        ariaLabel: 'email',
        external: {
          target: '',
          url: `mailto:${recruiterData?.email}`,
        },
      },
    },
  ];
  return recruiterData ? (
    <StyledWrapper margin={margin} mobileMargin={mobileMargin}>
      <StyledContent>
        <StyledDotsGridLeft width={2} height={7} color="complementary3" />
        <StyledDotsGrid width={isMdUp ? 4 : 7} height={7} color="complementary2" />
        {recruiterData?.backgroundImage ? (
          <StyledWpImage {...recruiterData.backgroundImage} />
        ) : (
          <StyledNoWpImage />
        )}
        <StyledCardWrapper>
          <BusinessCard
            image={recruiterData?.profileImage}
            fullName={recruiterData?.fullName}
            positionTitle={recruiterData?.department}
            text={consultantDescriptionText}
            contactList={contactList}
            position={recruiterText}
          />
        </StyledCardWrapper>
      </StyledContent>
    </StyledWrapper>
  ) : null;
};

export default RecruiterSection;
