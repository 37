import React from 'react';

import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledContent,
  StyledDotsGrid,
  StyledDotsGridLeft,
  StyledWpImage,
  StyledWrapper,
} from './background-image-offer.styles';

export type BackgroundImageOfferType = {
  backgroundImage: WpImageType;
};

const BackgroundImageOffer = ({ backgroundImage }: BackgroundImageOfferType) => {
  const { isMdUp } = useScreenService();
  return backgroundImage ? (
    <StyledWrapper>
      <StyledContent>
        <StyledDotsGridLeft width={2} height={7} color="complementary3" />
        <StyledDotsGrid width={isMdUp ? 4 : 7} height={7} color="complementary2" />
        <StyledWpImage {...backgroundImage} />
      </StyledContent>
    </StyledWrapper>
  ) : null;
};

export default BackgroundImageOffer;
