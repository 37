import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';

export const StyledDotsGridOffer = styled(DotsGrid)`
  display: none;
  z-index: 10;
  top: 73%;

  ${breakpoint.lg} {
    display: block;

    left: -18%;
  }

  ${breakpoint.xl} {
    left: -22%;
  }
`;
