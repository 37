import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import dotsGrid from 'components/molecules/dots-grid/dots-grid';
import wpImage from 'components/atoms/wp-image/wp-image';

export const StyledWrapper = styled.div<DefaultType>`
  margin: ${({ mobileMargin }) => mobileMargin || `0`};
  display: flex;
  justify-content: center;
  ${breakpoint.md} {
    margin: ${({ margin }) => margin || `0`};
  }
`;

export const StyledContent = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
`;
export const StyledDotsGrid = styled(dotsGrid)`
  top: 16px;
  left: 16px;

  ${breakpoint.md} {
    top: 32px;
    right: 32px;
    left: unset;
  }
`;

export const StyledDotsGridLeft = styled(dotsGrid)`
  display: none;

  ${breakpoint.lg} {
    display: block;
    bottom: -17%;
    right: unset;
    left: 32px;
  }
`;
export const StyledWpImage = styled(wpImage)`
  height: 256px;

  ${breakpoint.md} {
    height: 768px;
  }
`;
export const StyledNoWpImage = styled.div`
  background: ${({ theme }) => theme.colors.complementary1};
  height: 256px;

  ${breakpoint.md} {
    height: 768px;
  }
`;

export const StyledCardWrapper = styled.div`
  width: 100%;
  position: relative;

  ${breakpoint.md} {
    position: absolute;
    top: 13%;
    left: 11.5%;
    max-width: 555px;
    box-shadow: 0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06);
  }
`;
