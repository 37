import React from 'react';

import RichText from 'components/atoms/rich-text';
import Typography from 'components/atoms/typography';

import useCurrentLanguage from 'hooks/use-current-language';
import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledActionWrapper,
  StyledButtonApply,
  StyledTextWrapper,
  StyledWrapper,
} from './apply-offer.styles';

export type ApplyOfferType = {
  applicationprocedure: string;
  city: string;
  voivodship: string;
  workmode: string;
  applicationurl: string;
};

const ApplyOffer = ({
  applicationprocedure,
  voivodship,
  city,
  workmode,
  applicationurl,
}: ApplyOfferType) => {
  const { isLgUp } = useScreenService();
  const locationText = `${city ? `${city}, ` : ''}${voivodship}`;
  const shouldDisplayTypography = isLgUp && (locationText || workmode);
  const currentLanguage = useCurrentLanguage();
  const textApply = currentLanguage === 'PL' ? 'Aplikuj' : 'Apply';
  return (
    <StyledWrapper>
      {shouldDisplayTypography && (
        <StyledTextWrapper>
          <Typography html={locationText} variant="title4" color="gray80" />
          {workmode && <Typography html={workmode} variant="title4" color="gray80" />}
        </StyledTextWrapper>
      )}
      {applicationurl ? (
        <StyledButtonApply
          link={{ external: { url: applicationurl }, ariaLabel: applicationurl }}
          variant="primary"
          size="md"
          label={textApply}
        />
      ) : (
        <StyledActionWrapper>
          <RichText html={applicationprocedure} />
        </StyledActionWrapper>
      )}
    </StyledWrapper>
  );
};
export default ApplyOffer;
