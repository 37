import styled from 'styled-components';

import { breakpoint } from '../../../theme';
import { DefaultType } from '../../../types/default';
import wpImage from '../../atoms/wp-image/wp-image';
import dotsGrid from '../dots-grid/dots-grid';

export const StyledWrapper = styled.div<DefaultType>`
  margin: ${({ mobileMargin }) => mobileMargin || `0`};
  display: flex;
  justify-content: center;
  ${breakpoint.md} {
    margin: ${({ margin }) => margin || `0`};
  }
`;

export const StyledContent = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
`;
export const StyledDotsGrid = styled(dotsGrid)`
  top: 16px;
  left: 16px;

  ${breakpoint.md} {
    top: 32px;
    right: 32px;
    left: unset;
  }
`;
export const StyledWpImage = styled(wpImage)`
  height: 256px;

  ${breakpoint.md} {
    height: 768px;
  }
`;
export const StyledDotsGridLeft = styled(dotsGrid)`
  display: none;

  ${breakpoint.lg} {
    display: block;
    bottom: -17%;
    right: unset;
    left: 32px;
  }
`;
