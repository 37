import styled from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';

const width = 350;
export const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100vw;
  height: 72px;
  padding: ${({ theme }) => theme.gap.sm};
  background-color: ${({ theme }) => theme.colors.white};

  ${breakpoint.lg} {
    position: sticky;
    margin-right: ${`-${width / 2}px`};
    left: auto;
    bottom: auto;
    top: ${({ theme }) => theme.gap.mdl};
    right: 0;
    background-color: ${({ theme }) => theme.colors.grayscaleGray0};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.gap.sm};
    height: max-content;
    width: ${`${width}px`};
    padding: ${({ theme }) => theme.gap.smd};
  }
`;
export const StyledButton = styled(Button)`
  min-height: 40px;
  width: 100%;
`;

export const StyledActionWrapper = styled.div`
  a {
    width: 100%;
    display: block;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.primary50};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    justify-content: center;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 1.3;

    ${breakpoint.md} {
      padding: 10px 20px;
      font-size: 14px;
      line-height: 1.4;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary100};
    }
  }
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.xs};
`;

export const StyledButtonApply = styled(Button)`
  width: 100%;
`;
