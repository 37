import styled from 'styled-components';
import { breakpoint } from 'theme';

import typography from 'components/atoms/typography';
import wpImage from 'components/atoms/wp-image';

import Icon from '../../atoms/icon';

export const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: ${({ theme }) => `${theme.gap.md} 24px`};

  ${breakpoint.md} {
    padding: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledPosition = styled(typography)`
  margin-bottom: 24px;
`;

export const StyledName = styled(typography)`
  padding-block: ${({ theme }) => `${theme.gap.sm} ${theme.gap.xs}`};

  ${breakpoint.md} {
    padding-top: 24px;
  }
`;

export const StyledPositionTitle = styled(typography)`
  padding-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-bottom: 24px;
  }
`;

export const StyledDescription = styled(typography)`
  padding-bottom: ${({ theme }) => theme.gap.sm};
  max-width: 450px;

  ${breakpoint.md} {
    padding-bottom: 24px;
  }
`;

export const StyledContacts = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gap.xs};
`;

export const StyledContact = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  cursor: pointer;
  padding: 8px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray100};
  }

  ${breakpoint.md} {
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  svg {
    height: 16px;
    width: 16px;

    ${breakpoint.md} {
      height: 52.2px;
      width: 52.2px;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledPlaceHolderImage = styled(Icon)`
  width: 128px;
  height: 128px;

  ${breakpoint.md} {
    width: 192px;
    height: 192px;
  }
`;
export const StyledWpImage = styled(wpImage)`
  width: 128px;
  height: 128px;

  ${breakpoint.md} {
    width: 192px;
    height: 192px;
  }
`;
